html, body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
}

html {
    font-size: 16px; /* px means "pixels": the base font size is now 10 pixels high */
    font-family: "Roboto", sans-serif; /* this should be the rest of the output you got from Google Fonts */
}

#backgroundvideo {
    position:fixed;
    display:inline;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-height: 100%;
    min-width: 100%;
}

.cv {
    position:absolute;
    top: 10px;
    left: 20px;
    right: 20px;
    max-width: 800px;
    margin: auto;
    text-align: justify;
    overscroll-behavior: contain;
    background-color: #1D1D1B;
    padding: 0 20px 20px 20px;
    border: 5px solid #04060d;
    color: #e0e0e0;
}

h1, h2, h3 {
    margin: 0;
    padding: 20px 0;
    color: #68befb;
    text-shadow: 1px 1px 1px #0016ff;
}

p,
li {
    line-height: 1.25;
    letter-spacing: 1px;
}

strong {
    font-size: 16px;
    color : #76d261;
    text-shadow: 1px 1px 1px black,-1px -1px 1px black,-1px 1px 1px black,1px -1px 1px black;
}

.rounded {
    border-radius: 50%;
}

.icons {
    width: 25px;
}